import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { gql, useMutation } from "@apollo/client";
import { PrimaryButton } from "../../components/button";
import { classNames, useAppConfig } from "@ct-react/core";
import { useLocaleUrl, useNavigate } from "@ct-react/locale";
import { useCartData } from "./cart";
import "./cart-payment.scss";

const CART_CHECKOUT_GQL_MUT = gql`
  mutation CartCheckout($sessionId: String, $userId: String, $customer: CustomerInfoInput!, $hasFullAmountPayment: Boolean!, $afterPaymentUrl: AfterPaymentRedirectUrlInput!) {
    cartCheckout(sessionId: $sessionId, userId: $userId, customer: $customer, hasFullAmountPayment: $hasFullAmountPayment, afterPaymentUrl: $afterPaymentUrl) { id redirectUrl }
  }
`;

const CartPayment:FunctionComponent = ()=>{
    const intl = useIntl();
    const { baseUrl } = useAppConfig();
    const navigate = useNavigate();
    const { buildLocaleHref } = useLocaleUrl();
    const [cartData, setCardData] = useCartData();
    const [ paymentMethod, setPaymentMethod ] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [ cartCheckout ] = useMutation(CART_CHECKOUT_GQL_MUT);

    const onPay = useCallback(() => {
        setIsSubmitting(true);
        console.log("test")
        try {
            cartCheckout({
                variables: {
                sessionId : cartData.sessionId,
                userId : cartData.userId,
                customer: {
                    firstName : cartData.customer?.surname,
                    lastName : cartData.customer?.name,
                    email : cartData.customer?.email,
                    phone : cartData.customer?.tel,
                    address : {
                    street : cartData.customer?.street,
                    streetNumber : cartData.customer?.streetNbr,
                    streetComplement : cartData.customer?.complementaryAdress,
                    zip : cartData.customer?.npa,
                    city : cartData.customer?.city,
                    country : cartData.customer?.country
                    }
                },
                hasFullAmountPayment: paymentMethod,
                afterPaymentUrl: {
                    baseUrl,
                    successPath: buildLocaleHref("/checkout/paiement/valide"),
                    cancelPath: buildLocaleHref("/checkout/paiement/invalide")
                }
                }
            })
                .then(({ data: { cartCheckout } }) =>  !cartCheckout
                ? navigate("/checkout/paiement/valide")
                : window.location.href = cartCheckout.redirectUrl);
        } catch (error) {
            console.warn(error)
        }
    },[paymentMethod]);

    const renderButton = useMemo(() => {
        return(
            <PrimaryButton  value={intl.formatMessage({id:"btn-pay-now",defaultMessage : "Payer et finaliser la réservation"})}
                        setState={()=>onPay()}
                        disabled={isSubmitting}
                        />
        );
    },[isSubmitting]);

    return(
        <div className="payment">
        <div>
            <h5>{intl.formatMessage({id:"payment-model-payment", defaultMessage : "Modèle de paiement"})}</h5>
            <div    className={classNames({"selected" : paymentMethod })}
                    onClick={()=>setPaymentMethod(true)}
                    data-type="total"
                    >
                <div>
                    <p>{intl.formatMessage({id:"payment-pay-total", defaultMessage : "Payer le montant total"})}</p>
                    <span>
                      {!!cartData.items&& intl.formatNumber(cartData.items!.fullPrice.amount, {style: 'currency', currency: cartData.items?.fullPrice.currency})}
                    </span>
                    <span className="radio"></span>
                </div>
                <p>{intl.formatMessage({id:"payment-pay-now",defaultMessage : "Payer le total maintenant et vous aurez terminé."})}</p>
            </div>
            {
                cartData.items?.fullPrice.amount != cartData.items?.fullPrice.downPayment && (
                <div    className={classNames({"selected" : !paymentMethod })}
                        onClick={()=>setPaymentMethod(false)}
                        data-type="downPayment"
                        >
                    <div>
                        <p>{intl.formatMessage({id:"payment-pay-account-now", defaultMessage : "Payer une partie maintenant, puis l'autre partie plus tard"})}</p>
                        <span>
                            {!!cartData.items&& intl.formatNumber(cartData.items!.fullPrice.downPayment, {style: 'currency', currency: cartData.items?.fullPrice.currency})}
                        </span>
                        <span className="radio"></span>
                    </div>
                    <p>
                        {!!cartData.items&& intl.formatMessage(
                            {   id:"payment-pay-account-now-2",
                                defaultMessage : "Payer {total} {currency} maintenant et le solde ({downPayment} {currency}) vous sera prélevé 1 mois avant votre check-in. Aucun frais supplémentaires."},
                            {
                                currency : cartData.items?.fullPrice.currency,
                                total : cartData.items?.fullPrice.downPayment,
                                downPayment : Math.round(cartData.items?.fullPrice.amount - cartData.items?.fullPrice.downPayment)
                            }
                        )}
                    </p>
                </div>
                )
            }
        </div>
        <div>
            <h5>{intl.formatMessage({id:"price-detail",defaultMessage : "Détails du prix"})}</h5>
            <div>
                <p>{intl.formatMessage({id:"amount",defaultMessage : "Montant"})}</p>
                <span>
                    {!!cartData.items&& intl.formatNumber(cartData.items!.fullPrice.amount || 0, {style: 'currency', currency: cartData.items?.fullPrice.currency})}
                </span>
            </div>
            {
                cartData.items?.fullPrice.amount != cartData.items?.fullPrice.downPayment && (
                <div>
                    <p>{intl.formatMessage({id:"immediate-account",defaultMessage : "Acompte immédiat"})}</p>
                    <span>
                        {!!cartData.items&& intl.formatNumber(cartData.items!.fullPrice.downPayment || 0, {style: 'currency', currency: cartData.items?.fullPrice.currency})}
                    </span>
                </div>
                )
            }
        </div>
        <PrimaryButton  color="btnWhite"
                        value={intl.formatMessage({id:"btn-change-your-informations", defaultMessage : "Modifier vos informations"})}
                        setState={()=>navigate("/checkout/information")}
                        />
        {renderButton}
        </div>
    );
};



export default CartPayment;
