import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from "react";
import defaultFormData from "../../models/forms.data";
import { FormButton } from "../button";
import contactFormReducer from "./forms.reducer";
import { useIntl } from "react-intl";
import { gql, useMutation } from "@apollo/client";
import Loader from "../../components/loader";
import "./forms.scss";
import { FormCallback } from "./form-callback";
import { useAppConfig } from "@ct-react/core";
import { commonDefs } from "../../i18n";

const EMAIL_MUTATION = gql`
    mutation sendEmail(
    $agencyId: ID!,
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $phone: String!,
    $subject: String,
    $body: String!,
    $articleId: UUID,
    $emailAddress: EmailAddress
    ){
        mailAgency(
            agencyId: $agencyId,
            content: {
                firstName: $firstName,
                lastName: $lastName,
                email: $email,
                phone: $phone,
                subject: $subject,
                body: $body,
                articleId: $articleId
            },
            recipient:$emailAddress
        )
    }
`;

const initialFormData = new defaultFormData();
type FormEvent = React.FormEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>;
type ContacFormProps = {
    articleId?: string,
    type?: "weekly"|"seasonal"|"annual"|"sale",
    email?:string
}

const ContactForm: FunctionComponent<ContacFormProps> = ({ articleId, type, email }) => {

    const intl = useIntl();
    const { options: { agencyId } } = useAppConfig();
    const [formData, dispatchFormData] = useReducer<(formData: any, action: any) => any>(contactFormReducer, initialFormData);
    const [isDisabled,setIsDisabled] = useState<boolean>(true);
    const [succeeded, setsucceeded] = useState<boolean | null>(null);

    const [ sendEmail, { loading: actionLoading } ] = useMutation(EMAIL_MUTATION);

    const handleFormData = (e:FormEvent)=>{
        dispatchFormData({
            type: e.currentTarget.name,
            value: e.currentTarget.value,
          });
        if(Object.values(formData).includes(false)){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
    };

    const handleRecipient = ()=>{
        const subject = type ? type : formData.subject;
        switch (subject) {
            case ("sale"):
            case ("Acheter"):
            case ("Buy"):
            case ("Mettre en vente"):
            case ("Put on sale"):
                return "camille@maytain.com";
                break;

                case ("weekly"):
                case ("annual"):
                case ("seasonal"):
                case ("Louer"):
                case ("Rent"):
                case ("Mettre en location"):
                case ("Put on rent"):
                return "location@maytain.com";
                break;

                case ("Admin"):
                case ("Managment"):
                case ("Administration"):
                case ("Gérance"):
                return "administrations@maytain.com";
                break;

                default:
                return "info@maytain.com";
                break;
        }
    };

    const handleSubject = () => {
        type === "sale" && intl.formatMessage(commonDefs.sale)
        type === "weekly" && intl.formatMessage({ id:"weekly-rental", defaultMessage: "Location semaine"})
        type === "seasonal" && intl.formatMessage({ id:"seasonal-rental", defaultMessage: "Location saison"})
        type === "annual" && intl.formatMessage({ id:"annual-rental", defaultMessage: "Location année"})
    };

    useEffect(()=>{
        if(Object.values(formData).includes(false)){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
    },[formData]);

    const formCallback = useMemo(()=>{
        if(!!succeeded) return <FormCallback setState={()=>setsucceeded(null)} succeeded={succeeded}/>
        return undefined;
    },[succeeded])

    const handleSubmit = (e:FormEvent)=>{
        e.preventDefault();
        dispatchFormData({
            type: "reset",
        });
        sendEmail({
            variables: {
              agencyId,
              firstName : formData.surname,
              lastName : formData.name,
              email : formData.email,
              phone : formData.tel,
              subject : formData.subject != "" ? formData.subject : handleSubject(),
              body : formData.message,
              articleId,
              emailAddress : !!email? email : handleRecipient()
            }
        })
        .then(() => setsucceeded(true))
        .catch(() => setsucceeded(false) );

    };

    return(
        <>
            {actionLoading&& <Loader/>}
            <form id="contact-form" className="contact-form">
                <div className="input-block surname">
                    <label htmlFor="surname">{intl.formatMessage({id:"form-label-surname", defaultMessage: "Prénom"})}*</label>
                    <input
                        id="surname"
                        type="text"
                        name="surname"
                        value={formData.surname}
                        placeholder={intl.formatMessage({id:"form-placeholder-surname", defaultMessage: "Votre prénom"})}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.surname===""||!formData.surnameValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block name">
                    <label htmlFor="name">{intl.formatMessage({id:"form-label-name", defaultMessage: "Nom"})}*</label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder={intl.formatMessage({id:"form-placeholder-name", defaultMessage: "Votre nom"})}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.name===""||!formData.nameValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block email">
                    <label htmlFor="email">{intl.formatMessage({id:"email", defaultMessage: "Email"})}*</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder={intl.formatMessage({id:"form-placeholder-email", defaultMessage: "Votre email"})}
                        required
                        onInput={handleFormData}
                    />
                    <span>{formData.email===""||!formData.emailValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block tel">
                    <label htmlFor="tel">{intl.formatMessage({id:"phone", defaultMessage: "Téléphone"})}*</label>
                    <input
                        id="tel"
                        type="tel"
                        name="tel"
                        value={formData.tel}
                        placeholder={intl.formatMessage({id:"form-placeholder-phone", defaultMessage: "Votre numéro de téléphone"})}
                        onInput={handleFormData}
                    />
                    <span>{formData.tel===""||!formData.telValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block subject">
                    <label htmlFor="subject">{intl.formatMessage({id:"form-label-subject", defaultMessage: "Sujet"})}*</label>
                    <select id="subject" name="subject" required onInput={handleFormData}>
                        <option value={intl.formatMessage(commonDefs.toBuy)}>{intl.formatMessage(commonDefs.toBuy)}</option>
                        <option value={intl.formatMessage(commonDefs.toRent)}>{intl.formatMessage(commonDefs.toRent)}</option>
                        <option value={intl.formatMessage(commonDefs.putSale)}>{intl.formatMessage(commonDefs.putSale)}</option>
                        <option value={intl.formatMessage(commonDefs.putRent)}>{intl.formatMessage(commonDefs.putRent)}</option>
                        <option value={intl.formatMessage({id:"admin", defaultMessage: "Administration"})}>{intl.formatMessage({ id:"admin", defaultMessage: "Administration"})}</option>
                        <option value={intl.formatMessage({id:"managment", defaultMessage: "Gérance"})}>{intl.formatMessage({ id:"managment", defaultMessage: "Gérance"})}</option>
                    </select>
                </div>
                <div className="input-block message">
                    <label htmlFor="message">{intl.formatMessage({id:"form-label-message", defaultMessage: "Message"})}*</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        placeholder={intl.formatMessage({id:"form-placeholder-message", defaultMessage: "Votre message"})}
                        required
                        onInput={handleFormData}
                    >
                    </textarea>
                    <span>{formData.message===""||!formData.messageValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="legalMention">
                    <a>&gt; {intl.formatMessage({id:"form-legal-notice" , defaultMessage: "Mentions légales"})}</a>
                    <a>&gt; {intl.formatMessage({id:"form-data-protection-policy", defaultMessage: "Politique de protection des données"})}</a>
                </div>

                <FormButton value={intl.formatMessage({ id:"btn-send", defaultMessage: "Envoyer"})} disabled={isDisabled} onSubmit={handleSubmit}/>
            </form>
            {formCallback}
        </>
    )
}

export default ContactForm;
