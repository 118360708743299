import { DateRange } from "react-day-picker";

export class SearchData{
    type:string;
    typeOfArticle:string[];
    price:number[] | null;
    roomsRange:number[] | null;
    person:NbrPerson;
    stay:DateRange;
    minSurface:number;
    filters:string[];

    constructor(
        type:string = "weekly",
        typeOfArticle:string[] = [],
        price:null = null,
        roomsRange:number[] | null = null,
        person:NbrPerson = new NbrPerson(),
        stay:DateRange = {from:undefined,to:undefined},
        minSurface:number = 0,
        filters:string[] = []

    ){
        this.type = type;
        this.typeOfArticle = typeOfArticle;
        if(this.type === "sale"){this.price = [0,5000000]}
        if(this.type === "annual"){this.price = [0,5000]}
        if(this.type === "seasonal" || this.type === "weekly"){this.price = [0,30000]}
        this.roomsRange = roomsRange;
        this.person = person;
        this.stay = stay;
        this.minSurface = minSurface;
        this.filters = filters
    }
}

export class NbrPerson {
    adult: number;
    child: number;
    exempt: number;
     
    constructor(
     adult: number = 0,
     child: number = 0,
     exempt: number = 0,
    ) {
     this.adult = adult;
     this.child = child;
     this.exempt = exempt;
    }
}
