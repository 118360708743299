import React, { FunctionComponent, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from "react-intl";

import Banner from "../../components/banner";
import Section from "../../components/wrapper/section";
import { RentArticleCarousel, SaleArticleCarousel } from "../../components/carousel";
import { LayoutContext } from "../../contexts/layout";
import TwoColumn from "../../components/wrapper/twoColum";

import "./home.scss";
//@ts-ignore
import imageSection4 from "../../assets/agence-facade.jpg";
import SeoHelmet from "../../components/seo-helmet";
import { commonDefs } from "../../i18n";

const Home:FunctionComponent = ()=>{
    const intl = useIntl();
    const layout = useContext(LayoutContext)
    const isBrowser = layout.includes("browser")

    return(
        <>
            <SeoHelmet 
              title={intl.formatMessage({ id: "seo-title-home", defaultMessage: "Maytain | Votre agence immobilière située à Morgins, Valais."})} 
              description={intl.formatMessage({ id: "seo-title-home", defaultMessage: "Spécialistes en immobilier, nous proposons des locations vacances, saisonnières, annuelles et des biens à la vente. Trouvez le bien qui vous convient."})}
              canonical={intl.formatMessage({ id: "seo-title-home", defaultMessage: "/"})}
              image={imageSection4}
            />

            <Banner title={intl.formatMessage({id:"real-estate-agency", defaultMessage: "Agence immobilière" })} subtitle="maytain" full={true} />
            <Section
              title={intl.formatMessage({id:"accomodations-on-sale", defaultMessage: "Biens immobiliers à la vente"})}
              subtitle={intl.formatMessage(commonDefs.sale)}
              btnValue={intl.formatMessage({id:"btn-see-all-accomodations",  defaultMessage: "Voir tous les biens"})}
              btnLink="/acheter"
            >
                <SaleArticleCarousel/>
            </Section>
            <Section
              title={intl.formatMessage({id:"acomodations-on-rent", defaultMessage: "Biens immobiliers en location"})}
              subtitle={intl.formatMessage(commonDefs.rent)}
              btnValue={intl.formatMessage({id:"btn-see-all-accomodations", defaultMessage: "Voir tous les biens"})}
              btnLink="/louer/semaine"
              background="var(--light-grey)"
            >
                <RentArticleCarousel type="semaine"/>
            </Section>
            <Section className="home-about">
                <TwoColumn
                  title={intl.formatMessage({id:"home-title-1", defaultMessage: "Qui sommes-nous ?"})}
                  subtitle={intl.formatMessage({id:"maytain-agency",  defaultMessage: "L’agence Maytain"})}
                  btnValue={intl.formatMessage({id:"btn-know-more",  defaultMessage: "En savoir plus"})}
                  btnLink="/a-propos"
                  btnPosition={isBrowser?"start":"center"}
                  imageSrc={imageSection4}
                  alt={intl.formatMessage({ id: "img-text-alt-1", defaultMessage: "Chalet typique appartenant à l'agence immobilière Maytain à Morgins"})}
                  revert={false}
                >
                <p>
                {intl.formatMessage({id:"home-text-1",  defaultMessage: "L’Agence Maytain est une Sàrl familiale d’excellente réputation créée en 1970 et située au centre de Morgins dans un petit chalet typique du début du 20e siècle. Notre agence, située au centre de Morgins, coeur des Portes du Soleil, assume toutes les tâches liées à la gestion du patrimoine immobilier en station. Nous offrons un grand choix de logements à la vente et à la location à Morgins, du studio au grand chalet."})}
                </p>
                <ul className="aboutSection-list">
                    <li>
                      <div className="aboutSection-list-marker">
                        <div>
                          <FontAwesomeIcon icon={faCheck} size="xs"/>
                        </div>
                      </div>
                      <p>{intl.formatMessage({id:"buy-an-accomodation",  defaultMessage: "Acheter un bien"})}</p>
                    </li>
                    <li>
                      <div className="aboutSection-list-marker">
                        <div>
                          <FontAwesomeIcon icon={faCheck} size="xs"/>
                        </div>
                      </div>
                      <p>{intl.formatMessage({id:"rent-an-accomodation",  defaultMessage: "Louer un bien"})}</p>
                    </li>
                    <li>
                      <div className="aboutSection-list-marker">
                        <div>
                          <FontAwesomeIcon icon={faCheck} size="xs"/>
                        </div>
                      </div>
                      <p>{intl.formatMessage({id:"admin",  defaultMessage: "Administration"})}</p>
                    </li>
                    <li>
                      <div className="aboutSection-list-marker">
                        <div>
                          <FontAwesomeIcon icon={faCheck} size="xs"/>
                        </div>
                      </div>
                      <p>{intl.formatMessage(commonDefs.putSale)}</p>
                    </li>
                    <li>
                      <div className="aboutSection-list-marker">
                        <div>
                          <FontAwesomeIcon icon={faCheck} size="xs"/>
                        </div>
                      </div>
                      <p>{intl.formatMessage({id:"putOnRent",  defaultMessage: "Mettre en location"})}</p>
                    </li>
                    <li>
                      <div className="aboutSection-list-marker">
                        <div>
                          <FontAwesomeIcon icon={faCheck} size="xs"/>
                        </div>
                      </div>
                      <p>{intl.formatMessage({id:"managment",  defaultMessage: "Gérance"})}</p>
                    </li>
                </ul>
                </TwoColumn>
            </Section>
            {/* <Section
              className="blog-section"
              title={intl.formatMessage({id:"our-latest-news",  defaultMessage: "Nos dernières news"})}
              subtitle={intl.formatMessage({id:"hour-blog",  defaultMessage: "Notre blog"})}
              btnValue={intl.formatMessage({id:"btn-see-whole-blog", defaultMessage: "Voir tout le blog"})}
              btnLink="/blog"
              background="var(--light-grey)"
            >
                <Carousel responsive={true} slidePerPage={4}>
                </Carousel>
            </Section> */}
        </>
    )
}

export default Home
